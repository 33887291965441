<template>
    <breadCrumb/>
    <overseas/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import overseas from '../components/presence/overseas.vue'
export default {
    components: {
        breadCrumb, 
        overseas
    },
    data(){
        return{}
    }
}
</script>