<template>
    <section id="ft-featured" class="ft-featured-section">
		<div class="container">
			<div class="ft-section-title-2 headline pera-content text-center">
				<span class="sub-title">Overseas presence</span>
				<h2>Overseas
					<span>presence</span>
				</h2>
			</div>
			<div class="ft-featured-content">
				<div class="row justify-content-center">  
					<div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
						<div class="ft-featured-innerbox position-relative minHeight1"> 
							<div class="ft-featured-text headline pera-content">
								<h3><a href="javascript:void(0)">INDIA SUB CONTINENT</a></h3> 
                                <div class="addressWrap">
                                    <div class="title">BANGLADESH :</div>
                                    <div class="address">Chittagong</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">PAKISTAN :</div>
                                    <div class="address">Karachi</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">SRI LANKA :</div>
                                    <div class="address">Colombo</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">MALDIVES :</div>
                                    <div class="address">Male</div>
                                </div> 
							</div>
						</div>
					</div>
                    <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
						<div class="ft-featured-innerbox position-relative"> 
							<div class="ft-featured-text headline pera-content">
								<h3><a href="javascript:void(0)">MIDDLE EAST</a></h3>
                                <div class="addressWrap">
                                    <div class="title">UAE :</div>
                                    <div class="address">Jebel Ali</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">SAUDI ARABIA :</div>
                                    <div class="address">Dammam,Jeddah,Riyadh</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">OMAN :</div>
                                    <div class="address">Sohar, Salalah </div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">QATAR :</div>
                                    <div class="address">Hamad</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">BAHRAIN :</div>
                                    <div class="address">Bahrain </div>
                                </div> 
                                <div class="addressWrap">
                                    <div class="title">KUWAIT :</div>
                                    <div class="address">Shuwiakh,Shuaiba </div>
                                </div> 
                                <div class="addressWrap">
                                    <div class="title">IRAQ :</div>
                                    <div class="address">Umm Qasr</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">IRAN :</div>
                                    <div class="address">Bandar Abbas, Bushehr, Khorramshahr</div>
                                </div>
							</div>
						</div>
					</div>
                    <div class="col-lg-6 col-md-6 mr wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
						<div class="ft-featured-innerbox position-relative"> 
							<div class="ft-featured-text headline pera-content">
								<h3><a href="javascript:void(0)">SOUTH EAST ASIA</a></h3>
                                <div class="addressWrap">
                                    <div class="title">SINGAPORE :</div>
                                    <div class="address">Singapore</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">MALAYSIA :</div>
                                    <div class="address">Port Klang ( North & West), Penang,Pasir Gudang,Bintulu,Kota Kinabalu,Sibu, Miri,Sandakan,Tawau,Kuching</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">INDONESIA :</div>
                                    <div class="address">Jakarta, Surabaya, Belawan, Semrang, Palembang, Panjang</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">MYANMAR :</div>
                                    <div class="address">Yangon</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">VITENAM :</div>
                                    <div class="address">Ho Chi Minh, Haiphong</div>
                                </div> 
                                <div class="addressWrap">
                                    <div class="title">THAILAND :</div>
                                    <div class="address">Bangkok, Laem Chabang, Lat Krabang</div>
                                </div> 
                                <div class="addressWrap">
                                    <div class="title">PHILIPPINES :</div>
                                    <div class="address">Manila</div>
                                </div> 
							</div>
						</div>
					</div>
                    <div class="col-lg-6 col-md-6 mr wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
						<div class="ft-featured-innerbox position-relative minHeight2"> 
							<div class="ft-featured-text headline pera-content">
								<h3><a href="javascript:void(0)">RED SEA/AFRICA</a></h3>
                                <div class="addressWrap">
                                    <div class="title">DJIBOUTI :</div>
                                    <div class="address">Djibouti Port</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">SOMALIA :</div>
                                    <div class="address">Berbera</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">SUDAN :</div>
                                    <div class="address">Port Sudan</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">YEMEN :</div>
                                    <div class="address">Aden,Mukalla</div>
                                </div>
                                <div class="addressWrap">
                                    <div class="title">JORDAN :</div>
                                    <div class="address">Aqaba </div>
                                </div> 
                                <div class="addressWrap">
                                    <div class="title">EGYPT :</div>
                                    <div class="address">AL SOKHNA</div>
                                </div>  
							</div>
						</div>
					</div>
                    <div class="col-lg-6 col-md-6 mr wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
						<div class="ft-featured-innerbox position-relative"> 
							<div class="ft-featured-text headline pera-content">
								<h3><a href="javascript:void(0)">FAR EAST</a></h3>
                                <div class="addressWrap">
                                    <div class="title">CHINA :</div>
                                    <div class="address">Shanghai, Qingdao, Tianjin, Ningbo </div>
                                </div>  
							</div>
						</div>
					</div>
                    <div class="col-lg-6"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    data(){
        return{}
    }
}
</script>